// extracted by mini-css-extract-plugin
export var caseTestimonialImage = "SingleReport-module--caseTestimonialImage--4063d";
export var contactButton = "SingleReport-module--contactButton--20e55";
export var detailsBoxLeft = "SingleReport-module--detailsBoxLeft--76ee6";
export var heroBackgroundInnerCircleLeft = "SingleReport-module--heroBackgroundInnerCircleLeft--43dfd";
export var heroBackgroundInnerCircleOverflowText = "SingleReport-module--heroBackgroundInnerCircleOverflowText--f3ae4";
export var heroWhiteBlurCirce = "SingleReport-module--heroWhiteBlurCirce--df816";
export var includedStudies = "SingleReport-module--includedStudies--73549";
export var includedStudiesBox = "SingleReport-module--includedStudiesBox--f782f";
export var includedStudiesBoxBottom = "SingleReport-module--includedStudiesBoxBottom--023e8";
export var includedStudiesImage = "SingleReport-module--includedStudiesImage--b7223";
export var includedStudiesInner = "SingleReport-module--includedStudiesInner--a9598";
export var keyTopicsImage = "SingleReport-module--keyTopicsImage--c25ad";
export var reportBtn = "SingleReport-module--reportBtn--79195";
export var reportBtnGerman = "SingleReport-module--reportBtnGerman--23ddd";
export var reportDetailsBoxRight = "SingleReport-module--reportDetailsBoxRight--3fa23";
export var reportKeyTopics = "SingleReport-module--reportKeyTopics--0b6bf";
export var reportKeyTopicsRow = "SingleReport-module--reportKeyTopicsRow--a15ad";
export var reportTopNavigation = "SingleReport-module--reportTopNavigation--8e0b8";
export var reportTopicsItemBox = "SingleReport-module--reportTopicsItemBox--e1666";
export var singleCaseTestimonial = "SingleReport-module--singleCaseTestimonial--7bb1c";
export var singleCaseTestimonialInner = "SingleReport-module--singleCaseTestimonialInner--d03a5";
export var singleCaseTextBox = "SingleReport-module--singleCaseTextBox--413e5";
export var singleCaseTextBoxLast = "SingleReport-module--singleCaseTextBoxLast--6d395";
export var singleReport = "SingleReport-module--singleReport--b30fd";
export var singleReportDetails = "SingleReport-module--singleReportDetails--2d09e";
export var testimonialBody = "SingleReport-module--testimonialBody--c24c9";
export var testimonialContent = "SingleReport-module--testimonialContent--43e32";
export var testimonialName = "SingleReport-module--testimonialName--3f22b";
export var topicsColumbLeft = "SingleReport-module--topicsColumbLeft--38418";
export var topicsColumbRight = "SingleReport-module--topicsColumbRight--c5c2c";
export var topicsItem = "SingleReport-module--topicsItem--9c8d1";